/* eslint-disable */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvestAndTradeCheckQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InvestAndTradeCheckQuery = { __typename: 'Query', offers: Array<{ __typename: 'Offer', id: string, hideOffer?: boolean | null, status: Types.OfferStatus }>, sellOrders: Array<{ __typename: 'SellOrder', id: string, status: Types.SellOrderStatus }>, portfolio: { __typename: 'Portfolio', portfolioOrders: Array<{ __typename: 'AllocationRequest', id: string, allocationRequestStatus: Types.AllocationRequestStatus } | { __typename: 'BuyOrder', id: string, buyOrderStatus: Types.BuyOrderStatus } | { __typename: 'SellOrder', id: string, sellOrderStatus: Types.SellOrderStatus } | { __typename: 'UnitRedemptionRequest', id: string, unitRedemptionRequestStatus: Types.UnitRedemptionStatus }> } };


export const InvestAndTradeCheckDocument = gql`
    query InvestAndTradeCheck {
  offers {
    id
    hideOffer
    status
  }
  sellOrders {
    id
    status
  }
  portfolio {
    portfolioOrders {
      ... on AllocationRequest {
        id
        allocationRequestStatus: status
      }
      ... on BuyOrder {
        id
        buyOrderStatus: status
      }
      ... on SellOrder {
        id
        sellOrderStatus: status
      }
      ... on UnitRedemptionRequest {
        id
        unitRedemptionRequestStatus: status
      }
    }
  }
}
    `;

/**
 * __useInvestAndTradeCheckQuery__
 *
 * To run a query within a React component, call `useInvestAndTradeCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestAndTradeCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestAndTradeCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestAndTradeCheckQuery(baseOptions?: Apollo.QueryHookOptions<InvestAndTradeCheckQuery, InvestAndTradeCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestAndTradeCheckQuery, InvestAndTradeCheckQueryVariables>(InvestAndTradeCheckDocument, options);
      }
export function useInvestAndTradeCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestAndTradeCheckQuery, InvestAndTradeCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestAndTradeCheckQuery, InvestAndTradeCheckQueryVariables>(InvestAndTradeCheckDocument, options);
        }
export type InvestAndTradeCheckQueryHookResult = ReturnType<typeof useInvestAndTradeCheckQuery>;
export type InvestAndTradeCheckLazyQueryHookResult = ReturnType<typeof useInvestAndTradeCheckLazyQuery>;
export type InvestAndTradeCheckQueryResult = Apollo.QueryResult<InvestAndTradeCheckQuery, InvestAndTradeCheckQueryVariables>;