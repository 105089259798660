"use client";

import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { usePathname } from "next/navigation";
import {
	Link,
	LinkVariant,
	Breakpoint,
	Button,
	ButtonVariant,
	ButtonSize,
	useModalState,
	useValueAtBreakpoint,
} from "@jasperlabs/jux-next";
import { NavLink } from "components/organisms/navigation/NavigationLink";
import dynamic from "next/dynamic";
import { useSession } from "contexts/session";
import RenderIfCondition from "components/organisms/RenderIfCondition";
import LoginLink from "components/organisms/LoginLink";
import {
	TenantLogoLink,
	AccountDropdownMenu,
	InvestingEntitiesDropdownMenu,
} from "../../organisms/navigation";

import { MobileNotificationsLink } from "./NotificationsLink";
import AppHeader from "./AppHeader";

import { useInvestAndTradeCheckQuery } from "./AppHeaderPrimary.generated";

const MobileNavigation = dynamic(
	() => import("../../organisms/navigation/MobileNavigation"),
	{ ssr: false },
);

const NavItemWithCountPill = ({
	name,
	count,
}: {
	name: string;
	count: number;
}) => (
	<div className="flex items-center gap-1">
		{name}
		<RenderIfCondition condition={count > 0}>
			<div className="flex items-center justify-center p-[6px] h-4 min-w-[16px] bg-[#FFFFFF33] rounded-full text-white text-xs focus:!no-underline">
				{count}
			</div>
		</RenderIfCondition>
	</div>
);

function AppHeaderPrimary() {
	const { hasAuthenticatedSession } = useSession();
	const modalState = useModalState();
	const [isClient, setIsClient] = useState(false);
	const pathname = usePathname();

	const { data, refetch } = useInvestAndTradeCheckQuery();

	const buttonSize = useValueAtBreakpoint({
		[Breakpoint.XS]: ButtonSize.Small,
		[Breakpoint.SM]: ButtonSize.Small,
		[Breakpoint.MD]: ButtonSize.Medium,
		[Breakpoint.LG]: ButtonSize.Medium,
		[Breakpoint.XL]: ButtonSize.Medium,
	});

	useEffect(() => {
		setIsClient(true);
	}, []);

	useEffect(() => {
		refetch();
	}, [pathname, refetch]);

	if (!isClient) {
		return null;
	}

	const offersCount = Number(
		data?.offers?.filter((offer) => !offer.hideOffer)?.length,
	);
	const openOffersCount = Number(
		data?.offers?.filter((offer) => !offer.hideOffer && offer.status === "OPEN")
			?.length,
	);
	const sellOrdersCount = Number(
		data?.sellOrders?.filter((sellOrder) => sellOrder.status === "OPEN")
			?.length,
	);

	const activeOrdersCount = Number(
		data?.portfolio?.portfolioOrders?.filter((order) => {
			if (order.__typename === "AllocationRequest") {
				return (
					order.allocationRequestStatus !== "COMPLETED" &&
					order.allocationRequestStatus !== "CANCELLED"
				);
			}

			if (order.__typename === "BuyOrder") {
				return order.buyOrderStatus !== "CANCELLED";
			}

			if (order.__typename === "SellOrder") {
				return order.sellOrderStatus !== "CANCELLED";
			}

			if (order.__typename === "UnitRedemptionRequest") {
				return order.unitRedemptionRequestStatus !== "CANCELLED";
			}

			return order satisfies never;
		})?.length,
	);

	const hasOffers = offersCount > 0;
	const hasSellOrders = sellOrdersCount > 0;

	if (hasAuthenticatedSession && !modalState.isOpen) {
		return (
			<AppHeader data-testid="app-header-primary">
				<AppHeader.Layout
					left={
						<div>
							<TenantLogoLink />
						</div>
					}
					center={
						<div className="hidden lg:flex items-center justify-start gap-5">
							<RenderIfCondition condition={!!hasOffers}>
								<Link
									as={NavLink}
									href="/invest"
									variant={LinkVariant.NavInverted}
									className="text-subheading-sm"
									partialMatch
								>
									<NavItemWithCountPill name="Invest" count={openOffersCount} />
								</Link>
							</RenderIfCondition>
							<RenderIfCondition condition={!!hasSellOrders}>
								<Link
									as={NavLink}
									href="/trade"
									variant={LinkVariant.NavInverted}
									className="text-subheading-sm"
									partialMatch
								>
									<NavItemWithCountPill name="Trade" count={sellOrdersCount} />
								</Link>
							</RenderIfCondition>
							<Link
								as={NavLink}
								href="/portfolio"
								variant={LinkVariant.NavInverted}
								className="text-subheading-sm"
								partialMatch
							>
								Portfolio
							</Link>
							<Link
								as={NavLink}
								href="/orders"
								variant={LinkVariant.NavInverted}
								className="text-subheading-sm"
								partialMatch
							>
								<NavItemWithCountPill name="Orders" count={activeOrdersCount} />
							</Link>
							<Link
								as={NavLink}
								href="/documents"
								variant={LinkVariant.NavInverted}
								className="text-subheading-sm"
							>
								Documents
							</Link>
							<Link
								as={NavLink}
								href="/transactions"
								variant={LinkVariant.NavInverted}
								className="text-subheading-sm"
							>
								Transactions
							</Link>
						</div>
					}
					right={
						<div className="flex items-center justify-end ml-auto sm:gap-2 lg:min-w-[275px]">
							<div className="hidden lg:flex gap-2 items-center">
								<MobileNotificationsLink />
								<span className="bg-nav-link h-9 w-[1px]" />
								<InvestingEntitiesDropdownMenu />
								<AccountDropdownMenu />
							</div>
							<div className="lg:hidden">
								<MobileNavigation
									hasOffers={hasOffers}
									sellOrdersCount={sellOrdersCount}
									offersCount={openOffersCount}
									activeOrdersCount={activeOrdersCount}
								/>
							</div>
						</div>
					}
				/>
			</AppHeader>
		);
	}

	return (
		<AppHeader data-testid="app-header-primary">
			<AppHeader.Layout
				left={
					<div className="lg:min-w-[275px]">
						<TenantLogoLink />
					</div>
				}
				right={
					<LoginLink>
						<Button variant={ButtonVariant.InvertedSecondary} size={buttonSize}>
							Login
						</Button>
					</LoginLink>
				}
				variant="simple"
			/>
		</AppHeader>
	);
}

gql`
	query InvestAndTradeCheck {
		offers {
			id
			hideOffer
			status
		}
		sellOrders {
			id
			status
		}
		portfolio {
			portfolioOrders {
				... on AllocationRequest {
					id
					allocationRequestStatus: status
				}
				... on BuyOrder {
					id
					buyOrderStatus: status
				}
				... on SellOrder {
					id
					sellOrderStatus: status
				}
				... on UnitRedemptionRequest {
					id
					unitRedemptionRequestStatus: status
				}
			}
		}
	}
`;

export default AppHeaderPrimary;
