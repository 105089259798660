import clsx from "clsx";
import React, { forwardRef } from "react";
import { IconSlimChevron } from "../icon";

export type TableProps = {
	children: React.ReactNode;
	className?: string;
} & React.HTMLAttributes<HTMLTableElement>;

const TableComponent = forwardRef<HTMLTableElement, TableProps>(
	({ children, className, ...props }, ref) => (
		<table ref={ref} className={clsx("w-full", className)} {...props}>
			{children}
		</table>
	),
);

export type TableHeaderProps = {
	children: React.ReactNode;
} & React.HTMLAttributes<HTMLTableSectionElement>;

const TableHeader = forwardRef<HTMLTableSectionElement, TableProps>(
	({ children, ...props }, ref) => (
		// @ts-ignore I have no idea
		<thead ref={ref} className="table w-full table-fixed" {...props}>
			<tr className="border-muted h-12 border-b bg-neutral-50">{children}</tr>
		</thead>
	),
);

export type TableColumnProps = {
	children: React.ReactNode;
	className?: string;
	sortable?: boolean;
	sorted?: boolean;
	sortDirection?: "ASC" | "DESC";
} & React.HTMLAttributes<HTMLTableCellElement>;

const TableColumn = forwardRef<HTMLTableCellElement, TableColumnProps>(
	({ children, className, sortable, sorted, sortDirection, ...props }, ref) => (
		<th
			ref={ref}
			className={clsx(
				"py-3 pl-1.5 pr-1.5 first:pl-3 last:pr-3",
				"lg:pl-2 lg:pr-2 lg:first:pl-4 lg:last:pr-4",
				"text-body-xs text-color-body-muted",
				sortable && "cursor-pointer",
				className,
			)}
			{...props}
		>
			{sortable ? (
				<div className="inline-table relative">
					{children}
					<div className="ml-[6px] mr-2 inline-table absolute top-1/2 -right-6 transform -translate-y-1/2">
						<IconSlimChevron
							className={clsx(
								"text-[10px] transition-all duration-150",
								sorted
									? [
											"text-color-body-muted",
											sortDirection === "ASC" && "rotate-90",
											sortDirection === "DESC" && "rotate-[270deg]",
										]
									: "rotate-[270deg] text-neutral-300",
							)}
						/>
					</div>
				</div>
			) : (
				children
			)}
		</th>
	),
);

export type TableBodyProps = {
	children: React.ReactNode;
	className?: string;
} & React.HTMLAttributes<HTMLTableSectionElement>;

const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
	({ children, className, ...props }, ref) => (
		<tbody
			ref={ref}
			className={clsx("divide-muted block h-full divide-y", className)}
			{...props}
		>
			{children}
		</tbody>
	),
);

export type TableRowProps = {
	children: React.ReactNode;
	className?: string;
	height?: "sm" | "md" | "lg";
	interactive?: boolean;
	wrapper?: (children: React.ReactNode) => React.ReactNode;
} & React.HTMLAttributes<HTMLTableRowElement>;

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
	({ children, className, height = "sm", interactive, ...props }, ref) => (
		<tr
			ref={ref}
			className={clsx(
				"table w-full table-fixed group/table-row",
				height === "sm" && "h-12",
				height === "md" && "h-16",
				height === "lg" && "h-24",
				interactive &&
					"cursor-pointer hover:bg-neutral-100 transition-colors align-middle",
				className,
			)}
			{...props}
		>
			{children}
		</tr>
	),
);

export type TableCellProps = {
	children: React.ReactNode;
	className?: string;
} & React.HTMLAttributes<HTMLTableCellElement>;

const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
	({ children, className, ...props }, ref) => (
		<td
			ref={ref}
			className={clsx(
				"py-3 pl-1.5 pr-1.5 first:pl-3 last:pr-3",
				"lg:py-4 lg:pl-2 lg:pr-2 lg:first:pl-4 lg:last:pr-4",
				"text-body-xs leading-normal text-neutral-700 align-middle",
				className,
			)}
			{...props}
		>
			{children}
		</td>
	),
);

const TableFooter = forwardRef<HTMLTableSectionElement, TableProps>(
	({ children, ...props }, ref) => (
		// @ts-ignore
		<tfoot
			ref={ref}
			className="table w-full table-fixed border-t border-muted divide-y divide-muted"
			{...props}
		>
			{children}
		</tfoot>
	),
);

export const Table = Object.assign(TableComponent, {
	Header: TableHeader,
	Body: TableBody,
	Column: TableColumn,
	Row: TableRow,
	Cell: TableCell,
	Footer: TableFooter,
});
