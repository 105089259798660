"use client";

import React, { ReactElement } from "react";
import Link, { LinkProps } from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useSession } from "contexts/session";

function LoginLink(
	props: Omit<LinkProps, "href" | "children"> & {
		children: React.ReactNode;
	},
) {
	const returnUrl = usePathname();
	return <Link href={`/login?returnUrl=${returnUrl}`} {...props} />;
}

export function LoginOrCreateAccountIfUnauthenticated({
	action = "login",
	children,
}: {
	action?: "login" | "create-account";
	children: React.ReactNode;
}) {
	const returnUrl = usePathname();
	const router = useRouter();
	const { hasAuthenticatedSession } = useSession();

	if (hasAuthenticatedSession) {
		return <>{children}</>;
	}

	function handleClick(e: React.MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		if (action === "login") {
			router.push(`/login?returnUrl=${returnUrl}`);
		} else {
			router.push(`/create-account?returnUrl=${returnUrl}`);
		}
	}

	return (
		<>
			{React.Children.map(children, (child) => {
				if (!React.isValidElement(child)) {
					return child;
				}

				return React.cloneElement(child as ReactElement, {
					onClick: (event: React.MouseEvent) => {
						handleClick(event);
					},
				});
			})}
		</>
	);
}

export default LoginLink;
